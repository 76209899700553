import { Component, EventEmitter, Output } from '@angular/core';
import { WetoComponent } from '../weto/weto.component';
import { FAI_WIDGET_ROUTES } from 'src/app/constants/fai';

@Component({
    selector: 'tlv-weto-imb',
    templateUrl: './weto-imb.component.html',
    styleUrls: ['./weto-imb.component.scss']
})
export class WetoImbComponent extends WetoComponent {
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public override end = new EventEmitter<boolean>(true);
    public FAI_WIDGET_ROUTES = FAI_WIDGET_ROUTES;
}
